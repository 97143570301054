.bg-1{
    background-color: #f4f4f4 !important;
}
.bg-2{
    background-color: #FCFCFC ;
}
.btn1{
    background-color: #a6a6a6    !important;
    border: #a6a6a6    !important;
    color: #fff !important;
}
.btn2-outline{
    background-color: #e7e7e7    !important;
    border: 1px solid #a6a6a6 !important;
    color: #868686 !important;
}
.btn2{
    Color: #1c1c1c !important;
    background-color: #e7e7e7    !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    Color: #1c1c1c !important;
    background-color: #bdbdbd    !important;
}